<template>
  <div style="margin: 0 10px">
    <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="申报请求" name="first" :disabled="!Auth.includes('list_declare')">
        <Declare :entOptions="entOptions" />
      </el-tab-pane>
      <el-tab-pane label="回执接收" name="second" :disabled="!Auth.includes('list_receipt')">
        <Receipt />
      </el-tab-pane>
      <el-tab-pane label="应用企业API" name="third" :disabled="!Auth.includes('list_api_log')">
        <Application :entOptions="entOptions" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: "importMonitorRecords",
};
</script>

<script setup>
import { ref, onMounted } from "vue";
import Declare from "./components/Declare";
import Receipt from "./components/Receipt";
import Application from "./components/Application";
import { useCache } from "@/hooks/useCache";
import { list_ent } from "@/api/bank/app.js";

const activeName = ref("");

const { wsCache } = useCache();
const Auth = wsCache.get("Auth");

if (!Auth.includes('list_declare') && !Auth.includes('list_receipt')) {
  activeName.value = 'third'
} else if (!Auth.includes('list_declare')) {
  activeName.value = 'second'
} else {
  activeName.value = 'first'
}

// 获取企业
let entOptions = ref([]);
// 发起请求获得币值信息
const getEntOptions = async () => {
  const res = await list_ent();
  if (res) {
    entOptions.value = res.data.list;
  }
};

onMounted(() => {
  getEntOptions();
});
</script>

<style scoped>
:deep(.el-tabs__nav-wrap::after) {
  background-color: #fff !important;
}

.demo-tabs>.el-tabs__content {
  color: #6b778c;
  font-size: 32px;
  font-weight: 600;
}
</style>
